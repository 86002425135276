<template>
  <div>
    <div
      ref="privacy"
      @click="handleClick"
    />
    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import sanitizeHTML from 'sanitize-html'
import axios from '@axios'

export default {
  data() {
    return {
      id: 0,
      content: null,
      otherPrivacy: '',
      clientList: ''
    }
  },
  watch: {
    $route(to) {
      if (!to.hash.includes('privacy')) this.id = to.hash === '' ? 0 : to.hash.replace('#', '')
    },
    id() {
      // 스크롤 위치 상단으로
      document.querySelector('.sub-wrap').scrollTo({ top: 0, behavior: 'smooth' })
      // 변경 약관 조회
      this.getPrivacyProcessTerms()
    },
  },
  created() {
    this.getPrivacyProcessTerms()
  },
  mounted() {
    if (window.opener) document.title = '개인정보처리방침'
  },
  methods: {
    getPrivacyProcessTerms() {
      axios.get(`/fu/terms/privacy/${this.id}`, {
      })
        .then(rs => {
          this.clientList = ''
          this.otherPrivacy = ''

          rs.data.history.forEach(item => {
            this.otherPrivacy += ` - <a id="other-privacy-${item.id}" href="#">${item.startDate} ~ ${item.endDate} 적용 (클릭)</a><br />`
          })

          this.$refs.privacy.innerHTML = sanitizeHTML(rs.data.contents, {
            allowedTags: false,
            allowedAttributes: false,
          })

          this.$refs.privacy.querySelector('#prev-privacy').innerHTML = ''
          this.$refs.privacy.querySelector('#prev-privacy').insertAdjacentHTML('beforeend', this.otherPrivacy)

          this.clientList += `
            <table style="margin-top: 20px;">
              <colgroup>
                <col style="width: 20%">
                <col style="width: 20%">
                <col style="width: 20%">
                <col style="width: 20%">
                <col style="width: 20%">
              </colgroup>
              <thead>
                <th colspan="5">
                  <strong>[제휴 제약사]</strong>
                </th>
              </thead>
              <tbody>
          `

          this.clientList += `<tr>`

          rs.data.clients.forEach((item, index) => {
            this.clientList += `<td>${item}</td>`
            if ((index + 1) % 5 === 0) this.clientList += `</tr><tr>`
          })

          this.clientList += `
                </tr>
              </tbody>
            </table>
          `

          this.$refs.privacy.querySelector('#clientList').innerHTML = ''
          this.$refs.privacy.querySelector('#clientList').insertAdjacentHTML('beforeend', this.clientList)
        })
        .catch(error => {
          this.showAlertDialog(error.response.data)
        })
    },

    handleClick(event) {
      const targetId = event.target.id

      if (targetId.indexOf('other-privacy-') !== -1) {
        // id 변경 시 watch 동작으로 해당 약관을 불러옴
        this.id = Number(targetId.replace('other-privacy-', ''))
      }
    },
  },
}
</script>
